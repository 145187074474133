import moment from 'moment';
import { NotificationAction, StatusType } from '../../pages/common/notification/notification.reducer';
import { EngagementType, ProductType, UsageReportConstants } from '../../helper/Constants';
import { actionTypes } from './UsageReport.action';
import { AppNotifier as VenusNotifier } from '../../helper/AppNotifier';
import { CSSProperties } from 'react';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';

export interface ICompanyLedgerModelV2 {
	count: number;
	usageDate: string;
	delivered: number;
	adjustments: number;
	totalUsage: number;
	deletedAfterDelivery: number;
	recalled: number;
	netUsage: number;
	reupload: number;
}

export interface IUsageReport {
	isLoading: boolean;
	usageReport: ICompanyLedgerModelV2[];
	count: 0;
}

export const reportColumns = {
	usageDate: 'Date',
	delivered: 'Delivered',
	totalUsage: 'Total Usage',
};

export const ssrReportColumns = {
	usageDate: 'Date',
	delivered: 'Delivered',
	deletedAfterDelivery: 'Deleted After Delivery',
	recalled: 'Recalled',
	reupload: 'Reprocess/Reupload',
	adjustments: 'Adjustments', 
	totalUsage: 'Total Usage'
};

export interface SelectOptions {
	value: any;
	label: string;
}

export const getProductTypeName = (productType: number | null | undefined) => {
	switch (productType) {
		case ProductType.Organizers:
			return 'Organizers ';
		case ProductType.Extensions:
			return 'Extensions ';
		case ProductType.SignaturesOneOff:
			return 'Signatures ';
		case ProductType.SignaturesMailMerge:
			return 'MailMerge ';
		case ProductType.Gather:
			return 'Gather';
		case ProductType.Returns:
		default:
			return 'Returns ';
	}
};

export const productTypes: SelectOptions[] = [
	{ label: 'Returns', value: ProductType.Returns },
	{ label: 'Signatures', value: ProductType.SignaturesOneOff },
	{ label: 'MailMerge', value: ProductType.SignaturesMailMerge },
	{ label: 'Organizers', value: ProductType.Organizers },
	{ label: 'Extensions', value: ProductType.Extensions },
	{ label: 'Gather', value: ProductType.Gather },
];

export const returnTypes: SelectOptions[] = [
	{ label: '1040', value: EngagementType.E1040 },
	{ label: '1041', value: EngagementType.E1041 },
	{ label: '1065', value: EngagementType.E1065 },
	{ label: '1120', value: EngagementType.E1120 },
	{ label: '1120S', value: EngagementType.E1120S },
	{ label: '990/T', value: EngagementType.E990 },
	{ label: '990PF', value: EngagementType.E990PF },
	{ label: '990EZ', value: EngagementType.E990EZ },
	{ label: 'All', value: EngagementType.None },
];

export enum DateRangeOptions {
	None = 0,
	Last7Days = 1,
	LastMonth = 2,
	LastYear = 3,
	MonthToDate = 4,
	YearToDate = 5,
	Custom = 6,
}

export const dateRangeOptions = [
	{ value: DateRangeOptions.Last7Days, label: 'Last 7 days' },
	{ value: DateRangeOptions.LastMonth, label: 'Last Month' },
	{ value: DateRangeOptions.LastYear, label: 'Last Year' },
	{ value: DateRangeOptions.MonthToDate, label: 'Month to Date' },
	{ value: DateRangeOptions.YearToDate, label: 'Year To Date' },
	{ value: DateRangeOptions.Custom, label: 'Custom' },
];

export interface IPageProps {
	pageIndex: number;
	pageSize: number;
}

export interface ISortColumn {
	sortBy: string;
	sortOrder: string;
}

export interface IDropdownStates {
	productType: ICustomDropdownOption | null;
	returnType: ICustomDropdownOption | null;
	dateType: ICustomDropdownOption | null;
	startDate: Date | null | undefined;
	endDate: Date | null | undefined;
}

export const buildQueryForRunReport = (
	dropdownStates: IDropdownStates,
	page: IPageProps,
	sortColumn: ISortColumn,
	isMonthlyReport: boolean,
) => {
	return `?fromDate=${dropdownStates.startDate ? moment(dropdownStates.startDate).format('MM/DD/YYYY') : ''}
	&toDate=${dropdownStates.endDate ? moment(dropdownStates.endDate).format('MM/DD/YYYY') : ''}
	&productType=${dropdownStates.productType?.value ?? ProductType.None}
	&engagementType=${dropdownStates.returnType?.value ?? EngagementType.None}
	&pageNo=${page.pageIndex}
	&pageSize=${page.pageSize}
	&sortBy=${sortColumn.sortBy}
	&sortDirection=${sortColumn.sortOrder}
	&isMonthlyReport=${isMonthlyReport}`;
};

export const buildQueryForExportToExcel = (
	dropdownStates: IDropdownStates,
	sortColumn: ISortColumn,
	isMonthlyReport: boolean,
) => {
	return `?fromDate=${dropdownStates.startDate ? moment(dropdownStates.startDate).format('MM/DD/YYYY') : ''}
	&toDate=${dropdownStates.endDate ? moment(dropdownStates.endDate).format('MM/DD/YYYY') : ''}
	&productType=${dropdownStates.productType?.value ?? ProductType.None}
	&engagementType=${dropdownStates.returnType?.value ?? EngagementType.None}
	&sortBy=${sortColumn.sortBy}
	&sortDirection=${sortColumn.sortOrder}
	&isMonthlyReport=${isMonthlyReport}`;
};

export const checkDropdownState = (dropdownStates: IDropdownStates, giveWarning: boolean = false) => {
	if (!dropdownStates.productType) {
		giveWarning && VenusNotifier.Warning(UsageReportConstants.noProductType);
		return false;
	}
	if (
		dropdownStates.productType &&
		(dropdownStates.productType.value === ProductType.Returns ||
			dropdownStates.productType.value === ProductType.Extensions) &&
		!dropdownStates.returnType
	) {
		giveWarning && VenusNotifier.Warning(UsageReportConstants.noReturnType);
		return false;
	}
	if (!dropdownStates.startDate || !moment(dropdownStates.startDate).isValid()) {
		giveWarning && VenusNotifier.Warning(UsageReportConstants.noStartDate);
		return false;
	}
	if (!dropdownStates.endDate || !moment(dropdownStates.endDate).isValid()) {
		giveWarning && VenusNotifier.Warning(UsageReportConstants.noEndDate);
		return false;
	}
	if (moment(dropdownStates.startDate) > moment(dropdownStates.endDate)) {
		giveWarning && VenusNotifier.Warning(UsageReportConstants.endDateLessThanStartDate);
		return false;
	}

	return true;
};

interface ICssProperties {
	tableCellStyle: CSSProperties;
	tableStyle: CSSProperties;
	tableRowStyle: CSSProperties;
}

export const printTableCss: ICssProperties = {
	tableCellStyle: { border: '1px solid #dddddd' },
	tableStyle: { fontFamily: 'sans-serif', width: '100%', borderCollapse: 'collapse' },
	tableRowStyle: {
		border: '1px solid #dddddd',
		textAlign: 'center',
		padding: '8px',
		lineHeight: '1.5',
	},
};

export type KnownAction = DispatchAction | NotificationAction;

export type DispatchAction = RequestUsageReport | ReceiveUsageReport | ErrorUsageReport;

interface ErrorUsageReport {
	type: actionTypes.ERROR_USAGE_REPORT;
	statusMessage: string;
	statusType: StatusType;
}

interface RequestUsageReport {
	type: actionTypes.REQUEST_USAGE_REPORT;
}

interface ReceiveUsageReport {
	type: actionTypes.RECEIVE_USAGE_REPORT;
	usageReport: ICompanyLedgerModelV2[];
}
